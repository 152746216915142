import {
  AttestationMask,
  BusinessIncorporationDateMask,
  BusinessPhoneMask,
  BusinessTypeInputMask,
  CopyInputMask,
  DisplayMask,
  DobMask,
  Numberformatmask,
  Numbermask,
  TermsAndConditionMask,
  WebsiteMask
} from './components';

const uiSchema = {
  businessType: {
    'ui:widget': BusinessTypeInputMask
  },
  telephone: {
    'ui:widget': BusinessPhoneMask
  },
  personalPhone: {
    'ui:widget': BusinessPhoneMask
  },
  statement: {
    displayName: {
      'ui:widget': DisplayMask
    }
  },
  merchantAgreementAccepted: {
    'ui:widget': TermsAndConditionMask
  },
  attestation: {
    'ui:widget': AttestationMask
  },
  providerAgreementAccepted: {
    'ui:widget': TermsAndConditionMask
  },
  dob: {
    'ui:widget': DobMask
  },
  businessIncorporationDate: {
    'ui:widget': BusinessIncorporationDateMask
  },
  idNumber: {
    'ui:widget': Numbermask
  },
  taxNumber: {
    'ui:widget': Numbermask
  },
  annualCardVolume: {
    'ui:widget': Numberformatmask
  },
  averageTransactionAmount: {
    'ui:widget': Numberformatmask
  },
  projectedMonthlyTransactionVolume: {
    'ui:widget': Numberformatmask
  },
  maxTransactionAmount: {
    'ui:widget': Numberformatmask
  },
  website: {
    'ui:widget': WebsiteMask
  },
  representative: {
    dob: {
      'ui:widget': DobMask
    },
    personalPhone: {
      'ui:widget': BusinessPhoneMask
    }
  },
  director: {
    dob: {
      'ui:widget': DobMask
    },
    personalPhone: {
      'ui:widget': BusinessPhoneMask
    }
  },
  executive: {
    dob: {
      'ui:widget': DobMask
    },
    personalPhone: {
      'ui:widget': BusinessPhoneMask
    }
  },
  webhookUrl: {
    'ui:widget': CopyInputMask
  }
};

const uiSchema1 = {
  items: {
    personalPhone: {
      'ui:widget': BusinessPhoneMask
    },
    dob: {
      'ui:widget': DobMask
    },
    idNumber: {
      'ui:widget': Numbermask
    }
  }
};
export { uiSchema, uiSchema1 };
