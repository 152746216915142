import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { get } from 'lodash';
import { connect } from 'react-redux';
import Filter from './Filter';
import { fetchIncomeByCustomers } from '../../../../../api/DashboardService';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import CenterSpinner from '../../../../../global/CenterSpinner';


const IncomeByCustomersReport = ({isCustomerIncomeExportEnabled}) => {
  const [startDate, setStartDate] = useState(moment()
    .startOf('year')
    .toDate());
  const [endDate, setEndDate] = useState(moment().toDate());
  const [customDate, setCustomDate] = useState('');
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [incomeByCustomersData, setIncomeByCustomersData] = useState([]);

  useEffect(async () => {
    setLoading(true);
    const response = await fetchIncomeByCustomers(startDate, endDate);
    if (response) {
      setIncomeByCustomersData(response?.data);
      setLoading(false);
    }
  }, [startDate, endDate]);

  const handleDateChange = (date, type) => {
    if (type === 'startDate') {
      setStartDate(date);
    } else if (type === 'endDate') {
      setEndDate(date);
    } else if (type === 'custom') {
      setStartDate(date?.value?.startDate);
      setEndDate(date?.value?.endDate);
      setCustomDate(date);
    }
  }

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const currency = incomeByCustomersData?.currency?.symbol;
  const totalIncome = incomeByCustomersData?.totalIncome;
  const incomeByCustomers = incomeByCustomersData?.breakdown;
  return (
    <div className="reports-page-wrapper">
      <div className="content-wrapper__main__fixed">
        <header className="py-header--page d-flex flex-wrap">
          <div className="py-header--title"><h2 className="py-heading--title">Income by Customers</h2></div>
          {isCustomerIncomeExportEnabled &&
            <div className='py-header--actions'>
              <ButtonDropdown isOpen={isDropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle caret color="primary" >Export</DropdownToggle>
                <DropdownMenu className="dropdown-menu-center">
                  <DropdownItem className="dropdown-menu-item-export">CSV</DropdownItem>
                  <DropdownItem className="dropdown-menu-item-export">PDF</DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </div>
          }
        </header>
        <div className="mt-2 mb-2">
          <Filter
            handleDateChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            customDate={customDate}
            showCustom
          />
        </div>
        {loading ? <CenterSpinner /> :
          <div className='content'>
            <div>
              <table className="py-table">
                <colgroup>
                  <col style={{ width: "65%" }} />
                  <col style={{ width: "35%" }} />
                </colgroup>
                <thead className="py-table__header">
                  <tr className="py-table__row text-uppercase">
                    <th className="py-table__cell" colspan="1"><div className="">Customers</div></th>
                    <th className="py-table__cell text-right" colspan="1">
                      All Income
                    </th>
                  </tr>
                </thead>
                <tbody className="py-table__rowgroup">
                  <tr className="py-table__row--header">
                    <td className="py-table__cell" colspan="2"><div className="">Income</div></td>
                  </tr>
                  {incomeByCustomers?.map(income => (
                    <tr className="py-table__row">
                      <td className="py-table__cell text-capitalize" colspan="1"><div className="">{income.customer}</div></td>
                      <td className="py-table__cell text-right" colspan="1">{currency}{income.totalAmount}</td>
                    </tr>
                  ))}
                  <tr className="py-table__row--total">
                    <td className="py-table__cell" colspan="1"><div className="">Total Income</div></td>
                    <td className="py-table__cell--grandtotal" colspan="1">
                      {currency}{totalIncome || "0.00"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

const mapStateToProps = ({ settings: { featureFlags } = {} }) => {
  const isCustomerIncomeExportEnabled = get(featureFlags, 'reports.customerIncomeExport', 'true') === 'true';
  return {
    isCustomerIncomeExportEnabled
  }
}

export default connect(mapStateToProps)(IncomeByCustomersReport);
