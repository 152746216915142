import React, { PureComponent, Fragment } from 'react'
import { Button } from 'reactstrap';
import { Link, browserHistory } from 'react-router-dom'

const GetRedirectUrl = ({row, className, viewButton, linkId, isPeyme, isCrowdFunding}) => {
  switch (row.paymentType.toLowerCase()) {
    case 'invoice':
      return <Fragment>
                <Link
                  className={!viewButton && className}
                  to={'/app/invoices/view/' + `${linkId ? row.linkId : row.invoiceId}`}
                >
                  {viewButton ? <Button color="primary" >View {row.paymentType}</Button> : `Invoice #${row.other ? row.other.invoiceNo : ''}`}
                </Link>
              </Fragment>
      break;
    case 'checkout':
      return <Fragment>
                <Link
                  className={!viewButton && className}
                  to={'/app/sales/checkouts/edit/' + `${linkId ? row.linkId : row.checkoutId}`}
                >
                  {viewButton ? <Button color="primary" >View {row.paymentType}</Button> :  `${row.other ? row.other.checkoutName : ''}`}
                </Link>
              </Fragment>
      break;
    case 'peyme':
      return <Fragment>
                {(!isPeyme || (isPeyme && !viewButton)) &&
                  <Link
                    className={!viewButton && className}
                    to={'/app/payyitme'}
                  >
                    {viewButton ? <Button color="primary" >View Payyit.Me</Button> : "Payyit.Me"}
                  </Link>
                }
              </Fragment>
      break;
    case 'funding':
      return <Fragment>
                {(!isCrowdFunding || (isCrowdFunding && !viewButton)) &&
                  <Link
                    className={!viewButton && className}
                    to={'/app/give'}
                  >
                    {viewButton ? <Button color="primary" >View Give</Button> : "Give"}
                  </Link>
                }
              </Fragment>
      break;
  
    default:
      break;
  }
}

export default GetRedirectUrl
