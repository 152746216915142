import React from 'react';
import {PlaidLink} from 'react-plaid-link';
import paymentService from '../../api/paymentService';

const Index = (props) => {
    const onSuccess = async (token) => {
        if (!!token) {
            try {
                props.handleLoading(true);
                const response = await paymentService.connectBank(token);
                if (response) {
                    props.handleLoading(false)
                    if (response.statusCode === 200) {
                        if (response.data.connectedBank.accounts.length > 0) {
                            props.getAccounts(response.data.connectedBank.accounts);
                        } else {
                            props.onShowSnackbar("Accounts not found", true)
                        }
                    } else {
                        props.onShowSnackbar(response.message, true)
                    }
                }
            } catch (err) {
                props.onShowSnackbar('Something went wrong, please try again later.', false)
            }
        }
    };
    return (
        <PlaidLink
            clientName="Payyit"
            env={process.env.REACT_APP_PLAID_ENV}
            countryCodes={[props.countryCodes]}
            linkCustomizationName="plaid_payment_onboarding"
            product={['auth']}
            className="w-100 px-3 btn btn-primary btn-block"
            style={{
                outline: 0,
                border: 0,
                padding: "12px 30px",
                background: "var(--bs-btn-bg)",
                borderRadius: 100
            }}
            publicKey={process.env.REACT_APP_PLAID_PUBLIC_KEY}
            onSuccess={onSuccess}
        >{props.buttonText}</PlaidLink>
    );
}

export default Index;