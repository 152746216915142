// import alipay from 'payment-icons/min/flat/alipay.svg';
// import diners from 'payment-icons/min/flat/diners.svg';
// import elo from 'payment-icons/min/flat/elo.svg';
// import hipercard from 'payment-icons/min/flat/hipercard.svg';
// import jcb from 'payment-icons/min/flat/jcb.svg';
// import maestro from 'payment-icons/min/flat/maestro.svg';
// import paypal from 'payment-icons/min/flat/paypal.svg';
// import unionpay from 'payment-icons/min/flat/unionpay.svg';
// import verve from 'payment-icons/min/flat/verve.svg';
//import discover from './../../assets/icons/banks/cc-discover.svg';
// import visa from 'payment-icons/min/flat/visa.svg';
// import bank from 'assets/bank-payment.svg';
// import amex from './../../assets/icons/banks/cc-amex.svg';
// import mastercard from './../../assets/icons/banks/cc-mastercard.svg';
// import master from './../../assets/icons/banks/cc-mastercard.svg';

const prefix = `${process.env.REACT_APP_CDN_URL}/static/web-assets`

const amex = `${prefix}/cc-amex.svg`
const bank = `${prefix}/cc-bank.svg`
const mastercard = `${prefix}/cc-mastercard.svg`
const visa = `${prefix}/cc-visa.svg`
const discover = `${prefix}/cc-discover.svg`
const hipercard = `${prefix}/cc-hipercard.svg`
const jcb = `${prefix}/cc-jcb.svg`
const diners = `${prefix}/cc-diners.svg`
const elo = `${prefix}/cc-elo.svg`
const maestro = `${prefix}/cc-maestro.svg`
const alipay = `${prefix}/alipay.svg`
// const paypal = `${prefix}/paypal.svg`
const paypal = `${prefix}/paypal_icon.jpg`
const unionpay = `${prefix}/unionpay.svg`
const verve = `${prefix}/verve.svg`
const cashapp = `${prefix}/cc-cashapp.svg`
const afterpay_clearpay = `${prefix}/cc-afterpay_clearpay.svg`
const klarna = `${prefix}/cc-klarna.svg`

const master = mastercard
export {
	alipay,
	amex,
	diners,
	discover,
	elo,
	hipercard,
	jcb,
	maestro,
	mastercard,
	master,
	paypal,
	unionpay,
	verve,
	visa,
	bank,
	cashapp,
	afterpay_clearpay,
	klarna
}