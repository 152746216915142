import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { get } from 'lodash';
import { connect } from 'react-redux';
import Filter from './Filter';
import { fetchCashFlow } from '../../../../../api/DashboardService';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import CenterSpinner from '../../../../../global/CenterSpinner';


const CashFlowReport = ({isCashFlowExportEnabled}) => {
  const [startDate, setStartDate] = useState(moment()
    .startOf('year')
    .toDate());
  const [endDate, setEndDate] = useState(moment().toDate());
  const [customDate, setCustomDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [cashFlowData, setCashFlowData] = useState([]);

  useEffect(async () => {
    setLoading(true);
    const response = await fetchCashFlow('', startDate, endDate);
    if (response) {
      setCashFlowData(response?.data);
      setLoading(false);
    }
  }, [startDate, endDate]);

  const handleDateChange = (date, type) => {
    if (type === 'startDate') {
      setStartDate(date);
    } else if (type === 'endDate') {
      setEndDate(date);
    } else if (type === 'custom') {
      setStartDate(date?.value?.startDate);
      setEndDate(date?.value?.endDate);
      setCustomDate(date);
    }
  }

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const currency = cashFlowData?.currency?.symbol;
  const cashFlow = cashFlowData?.values;
  const cashInFlow = currency+cashFlow?.inflow;
  const cashOutFlow = currency+cashFlow?.outflow;
  const cashNetChange = currency+cashFlow?.netChange;
  const isProfit = cashFlow?.netChange > 0;

  return (
    <div className="reports-page-wrapper">
      <div className="content-wrapper__main__fixed">
        <header className="py-header--page d-flex flex-wrap">
          <div className="py-header--title"><h2 className="py-heading--title">Cash Flow</h2></div>
          {isCashFlowExportEnabled &&
            <div className='py-header--actions'>
              <ButtonDropdown isOpen={isDropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle caret color="primary" >Export</DropdownToggle>
                <DropdownMenu className="dropdown-menu-center">
                  <DropdownItem className="dropdown-menu-item-export">CSV</DropdownItem>
                  <DropdownItem className="dropdown-menu-item-export">PDF</DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </div>
          }
        </header>
        <div className="mt-2 mb-2">
          <Filter
            handleDateChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            customDate={customDate}
            showCustom
          />
        </div>
        {loading ? <CenterSpinner /> :
          <div className='content'>
            <div className="cash-flow-overview--single">
              <div className="cash-flow-overview--single__block">
                <p className="py-text--block-label">GROSS CASH INFLOW</p>
                <span className="py-number--positive py-number">{cashInFlow}</span>
              </div>
              <h1 className="py-heading--title">-</h1>
              <div className="cash-flow-overview--single__block">
                <p className="py-text--block-label">GROSS CASH OUTFLOW</p>
                <span className="py-number--negative py-number">{cashOutFlow}</span>
              </div>
              <h1 className="py-heading--title">=</h1>
              <div className="cash-flow-overview--single__block">
                <p className="py-text--block-label">NET CASH CHANGE</p>
                <span className={`py-number--${isProfit ? 'positive' : 'negative'} py-number`}>{cashNetChange}</span>
              </div>
            </div>
            <div>
              <div>
                <table className="py-table">
                  <colgroup>
                    <col style={{ width: "65%" }} />
                    <col style={{ width: "35%" }} />
                  </colgroup>
                  <thead className="py-table__header">
                    <tr className="py-table__row">
                      <th className="py-table__cell" colspan="1"><div className="">CASH INFLOW AND OUTFLOW</div></th>
                      <th className="py-table__cell text-right" colspan="1">
                        <div className="">{moment(startDate).format('MMM DD, YYYY')}</div>
                        <div className="">to {moment(endDate).format('MMM DD, YYYY')}</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="py-table__rowgroup">
                    <tr className="py-table__row--header">
                      <td className="py-table__cell" colspan="2"><div className="">Operating Activities</div></td>
                    </tr>
                    {cashInFlow &&
                      <tr className="py-table__row">
                        <td className="py-table__cell" colspan="1"><div className="">Sales</div></td>
                        <td className="py-table__cell--grandtotal" colspan="1"><div className="">{cashInFlow}</div></td>
                      </tr>
                    }
                    {cashOutFlow &&
                      <tr className="py-table__row">
                        <td className="py-table__cell" colspan="1"><div className="">Purchases</div></td>
                        <td className="py-table__cell--grandtotal" colspan="1"><div className="">{cashOutFlow}</div></td>
                      </tr>
                    }
                    {cashNetChange &&
                      <tr className="py-table__row--total">
                        <td className="py-table__cell" colspan="1"><div className="">Net Cash from Operating Activities</div></td>
                        <td className="py-table__cell--grandtotal" colspan="1"><div className="">{cashNetChange}</div></td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

const mapStateToProps = ({ settings: { featureFlags } = {} }) => {
  const isCashFlowExportEnabled = get(featureFlags, 'reports.cashFlowExport', 'true') === 'true';
  return {
    isCashFlowExportEnabled
  }
}

export default connect(mapStateToProps)(CashFlowReport);
