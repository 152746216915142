import history from '../../../../../../customHistory'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  Button,
  Card,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  Table
} from 'reactstrap';
import { bindActionCreators } from 'redux'
import { DeleteModal } from '../../../../../../utils/PopupModal/DeleteModal';
import * as CustomerActions from '../../../../../../actions/CustomerActions';
import { _paymentMethodIcons, handleAclPermissions } from '../../../../../../utils/GlobalFunctions';
import { NoDataMessage } from '../../../../../../global/NoDataMessage';
import { _getUser } from '../../../../../../utils/authFunctions';
import DataTableWrapper from '../../../../../../utils/dataTableWrapper/DataTableWrapper';
import {columns, columnsWithoutPayment} from './customerSupportFile/constant';
import CenterSpinner from '../../../../../../global/CenterSpinner';
import { debounce } from 'lodash';

class Customer extends Component {
  state = {
    openConfimationModal: false,
    selectedDeleteCustomer: {},
    dropdownOpen: false,
    loading: false,
    permissions: [],
    offset: 1,
    limit: 10,
    totalData: 0,
    searchInput: ''
  };

  debouncedSearch = debounce((value) => {this.fetchCustomers(value ? `keyword=${value}` : '')}, 500)
  
  componentDidMount() {
    const { selectedBusiness } = this.props;
    const {acl} = _getUser(localStorage.getItem('token'))
    this.setState({permissions: acl.permissions[3].scope})
    document.title = selectedBusiness && selectedBusiness.organizationName ? `Payyit - ${selectedBusiness.organizationName} - Customers` : `Payyit - Customers`;
    const pageData = localStorage.getItem('paginationData')
    let queryData = `pageNo=${this.state.offset}&pageSize=${this.state.limit}`
    if(!!pageData){
    const {limit} = JSON.parse(pageData)
      queryData = `pageNo=${this.state.offset}&pageSize=${limit}`
      this.setState({queryData, limit})
    }
    this.fetchCustomers(queryData);
  }

  fetchCustomers(query) {
    this.setState({ loading: true })
    this.props.actions.fetchCustomers(query)
      .then(result => {
        this.setState({ loading: false })
      });

  }
  succesStyle = {
    "background-color": "green"
  };

  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  _handlePageChange = (type, {page, sizePerPage}) => {
    if(type === 'pagination'){
      let pageNo = !!page ? page : this.state.offset;
      if(sizePerPage !== this.state.limit){
        pageNo = 1
      }
      const query = `pageNo=${pageNo}&pageSize=${!!sizePerPage ? sizePerPage : this.state.limit}`
      this.fetchCustomers(query)
      this.setState({offset: pageNo, limit: sizePerPage})
      localStorage.setItem('paginationData', JSON.stringify({offset: pageNo, query, limit: sizePerPage}))
    }
  }

  handleInputChange = (e) => {
    const value = e.target.value
    this.setState({ searchInput: value })
    this.debouncedSearch(value)
  }

  render() {
    const { openConfimationModal, searchInput, loading } = this.state;
    const { selectedBusiness, customers: {meta, customers}, paymentSettings  } = this.props;

    return (
      <div className="customerWrapper">
        <div className="content-wrapper__main">
          
        <header class="py-header--page estimate-header-page">
          <div class="pull-right">                      
          {
            !handleAclPermissions(['Viewer']) && this.state.permissions.includes('write') && (
              <div className="py-header--actions" >
                <Dropdown className="d-inline-block mrR10" isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                  <DropdownToggle color="primary" outline caret>Import from..</DropdownToggle>
                  <DropdownMenu className="dropdown-menu-center">
                      <DropdownItem onClick={() => history.push('/app/sales/customer/csv')}>Import CSV</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <Button onClick={() => history.push('/app/sales/customer/add')} color="primary" >Add a customer</Button>
              </div>
            )
          }
          </div>
          <div class="py-header--title">
              <h1 class="py-heading--title">Customers</h1>
          </div>
          <div className='mb-3 mt-3'>
            <input
              name='searchInput'
              className="form-control py-select--small"
              placeholder="Search by customer name"
              value={searchInput}
              onChange={this.handleInputChange}
            />
          </div>
        </header>
          <div className="customer-list-table mt-3"
               key={(!paymentSettings.loading && !!paymentSettings.data.isOnboardingApplicable) ? "customer-table-with-payment" : "customer-table"}>
            {
              loading ? <CenterSpinner/> :
              !!customers && customers.length > 0 ?
                (
                  <DataTableWrapper
                    from="customerList"
                    data={customers}
                    columns={(!paymentSettings.loading && !!paymentSettings.data.isOnboardingApplicable) ? columns : columnsWithoutPayment}
                    classes={'py-table py-table--condensed'}
                    changePage={this._handlePageChange}
                    page={this.state.offset}
                    limit={this.state.limit}
                    totalData={meta.total}
                    defaultSorted={""}
                  />
                )
                : (<NoDataMessage
                  secondryMessage="Create a new customer and send them an invoice."
                  title="customer"
                  buttonTitle="customer"
                  btnText = "Add a"
                  add={() => history.push('/app/sales/customer/add')}
                  filter={false}
              />)
            }
        </div>
        <DeleteModal
          message={"Are you sure you want to delete this customer?"}
          openModal={openConfimationModal}
          onDelete={this.onDeleteCall}
          onClose={this.onCloseModal}
        />
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    customers: state.customerReducer.customers,
    isCustomerAdd: state.customerReducer.isCustomerAdd,
    isCustomerUpdate: state.customerReducer.isCustomerUpdate,
    selectedBusiness: state.businessReducer.selectedBusiness,
    paymentSettings: state.paymentSettings
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(CustomerActions, dispatch)
  };
};


export default withRouter((connect(mapStateToProps, mapDispatchToProps)(Customer)))
