import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { get } from 'lodash'

import {
  fetchAgedPayables,
  fetchOwingBills,
} from '../../../../../api/DashboardService'
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import CenterSpinner from '../../../../../global/CenterSpinner'

const AgedPayablesReport = ({ isAgedPayablesExportEnabled }) => {
  const [endDate, setDate] = useState(moment(new Date()).endOf('day').toDate())
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [agedPayablesData, setAgedPayablesData] = useState([])
  const [totalPayablesPerDuration, setTotalPayablesPerDuration] = useState([])
  const currency = agedPayablesData?.currency?.symbol
  const agedPayables = agedPayablesData?.payableData
  const totalPayablesPerVendor = agedPayablesData?.totalPerVendor
  const totalPayables = agedPayablesData?.grossTotal

  useEffect(async () => {
    setLoading(true)
    const response = await fetchAgedPayables(endDate)
    const perDurationPayables = await fetchOwingBills()
    if (response) {
      setAgedPayablesData(response?.data)
      setLoading(false)
    }
    if (perDurationPayables) {
      setTotalPayablesPerDuration(perDurationPayables?.data?.data)
    }
  }, [endDate])

  const handleDateChange = (date) => {
    setDate(date)
  }

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen)
  }

  const getTotalDueAmountPerVendor = (payableId) => {
    return totalPayablesPerVendor?.find(
      (totalPayable) => totalPayable._id === payableId
    )?.totalAmount
  }

  return (
    <div className="reports-page-wrapper">
      <div className="content-wrapper__main__fixed">
        <header className="py-header--page d-flex flex-wrap">
          <div className="py-header--title">
            <h2 className="py-heading--title">Aged Payables</h2>
          </div>
          {isAgedPayablesExportEnabled && (
            <div className="py-header--actions">
              <ButtonDropdown isOpen={isDropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle caret color="primary">
                  Export
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-center">
                  <DropdownItem className="dropdown-menu-item-export">
                    CSV
                  </DropdownItem>
                  <DropdownItem className="dropdown-menu-item-export">
                    PDF
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </div>
          )}
        </header>
        {/* <div className="mt-2 mb-2">
          <Filter
            handleDateChange={handleDateChange}
            endDate={endDate}
          />
        </div> */}
        {loading ? (
          <CenterSpinner />
        ) : (
          <div className="content">
            <div>
              <table className="py-table">
                <thead className="py-table__header">
                  <tr className="py-table__row text-uppercase">
                    <th className="py-table__cell" colSpan={3} />
                    <th className="py-table__cell" colspan={4}>
                      <div className="">Number of Days Overdue</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="py-table__rowgroup">
                  <tr className="py-table__row--header">
                    <td className="py-table__cell"></td>
                    <td className="py-table__cell text-center">
                      Not Yet Overdue
                    </td>
                    <td className="py-table__cell text-center is-item-highlighted">
                      30 or Less
                    </td>
                    <td className="py-table__cell text-center is-item-highlighted">
                      31 to 60
                    </td>
                    <td className="py-table__cell text-center is-item-highlighted">
                      61 to 90
                    </td>
                    <td className="py-table__cell text-center is-item-highlighted">
                      91 or More
                    </td>
                    <td className="py-table__cell text-center">Total Unpaid</td>
                  </tr>
                  {agedPayables?.map((payable) =>
                    payable.dueAmount ? (
                      <tr className="py-table__row">
                        <td className="py-table__cell text-capitalize">
                          {payable.vendor}
                        </td>
                        <td className="py-table__cell text-center">
                          {payable.timeSpan === '0'
                            ? `${currency}${parseFloat(
                                payable.dueAmount
                              ).toFixed(2)}`
                            : ''}
                        </td>
                        <td className="py-table__cell text-center is-item-highlighted">
                          {payable.timeSpan === '30'
                            ? `${currency}${parseFloat(
                                payable.dueAmount
                              ).toFixed(2)}`
                            : ''}
                        </td>
                        <td className="py-table__cell text-center is-item-highlighted">
                          {payable.timeSpan === '60'
                            ? `${currency}${parseFloat(
                                payable.dueAmount
                              ).toFixed(2)}`
                            : ''}
                        </td>
                        <td className="py-table__cell text-center is-item-highlighted">
                          {payable.timeSpan === '90'
                            ? `${currency}${parseFloat(
                                payable.dueAmount
                              ).toFixed(2)}`
                            : ''}
                        </td>
                        <td className="py-table__cell text-center is-item-highlighted">
                          {payable.timeSpan === '>90'
                            ? `${currency}${parseFloat(
                                payable.dueAmount
                              ).toFixed(2)}`
                            : ''}
                        </td>
                        <td className="py-table__cell text-center">
                          {currency}
                          {parseFloat(
                            getTotalDueAmountPerVendor(payable._id)
                          ).toFixed(2)}
                        </td>
                      </tr>
                    ) : (
                      ''
                    )
                  )}
                  <tr className="py-table__row--total">
                    <td className="py-table__cell text-left" colspan="1">
                      <div className="">Total Unpaid</div>
                    </td>
                    <td className="py-table__cell--grandtotal text-center">
                      {currency}
                      {parseFloat(
                        totalPayablesPerDuration?.[0]?.amount
                      ).toFixed(2)}
                    </td>
                    <td className="py-table__cell--grandtotal text-center is-item-highlighted">
                      {currency}
                      {parseFloat(
                        totalPayablesPerDuration?.[1]?.amount
                      ).toFixed(2)}
                    </td>
                    <td className="py-table__cell--grandtotal text-center is-item-highlighted">
                      {currency}
                      {parseFloat(
                        totalPayablesPerDuration?.[2]?.amount
                      ).toFixed(2)}
                    </td>
                    <td className="py-table__cell--grandtotal text-center is-item-highlighted">
                      {currency}
                      {parseFloat(
                        totalPayablesPerDuration?.[3]?.amount
                      ).toFixed(2)}
                    </td>
                    <td className="py-table__cell--grandtotal text-center is-item-highlighted">
                      {currency}
                      {parseFloat(
                        totalPayablesPerDuration?.[4]?.amount
                      ).toFixed(2)}
                    </td>
                    <td className="py-table__cell--grandtotal text-center">
                      {currency}
                      {parseFloat(totalPayables?.[0]?.total || 0).toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = ({ settings: { featureFlags } = {} }) => {
  const isAgedPayablesExportEnabled = get(featureFlags, 'reports.agedPayablesExport', 'true') === 'true';
  return {
    isAgedPayablesExportEnabled
  }
}

export default connect(mapStateToProps)(AgedPayablesReport)
